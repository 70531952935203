import React from 'react';
import useGlobalOutlets from './Hooks/useGlobalOutlets';

function withPageEntry(WrappedComponent) {
  return function NextComponent(props) {
    useGlobalOutlets();

    return <WrappedComponent {...props} />;
  };
}

export default withPageEntry;
